$breakpoint-phone: 360px;
$breakpoint-tablet: 768px; 
$breakpoint-desktop: 1024px;

$color-primary : #F6F6F6;
$color-secondary : #303551;
$color-special : #F2A90B;
$color-tertiary : #FFFFFF;
$color-lighter-grey-background: #C4C4C4;
$color-light-text: #000000;
$color-medium-text: #8C8C8C;
$color-dark-text: #000000;
$color-action: #EAEEF3;
$color-action-hover: #90b8f8;
$color-success:#158302;
$color-danger:#9e1e07;
$color-rewind: #FF6A00;
$color-green: #2baf1a;

article {
  font-size: medium;
  font-weight: 100;
  margin: 1rem;
  padding: 1vh 3vw;

}

.form-control:focus {
  border-color: $color-special;
  box-shadow: none;
}

.nav-link:hover {
    color: $color-special;
}

.nav-link {
    color: $color-secondary;
}

.nav-pills
.nav-link.active {
    background-color: $color-secondary;
}

.textarea-modal {
  
  max-width: 800vw;
  border-radius: 0.5rem;
  border: solid 0.1rem $color-dark-text;
  padding: 1vh 1.5vw 1vh 1.5vw;
  color: $color-dark-text;
  font-size: larger;
  // margin: 0.5rem 0 0.5rem 0;
  
}

// MODAL 

// .modal-header {
//     border: none;
//     margin-right:3px ;  
//   }
  
// .modal-header span {
//     font-size: 2rem;
//     border: none;
//     color: black;
//     text-align: end;
//     align-items: baseline;
// }

// Button Modal
.btn-modal {
    top: 2rem;
    right: 2rem;
    min-width: 120px;
    padding: 12px 10px;
    border-radius: 0.2rem;
    text-decoration: none;
    border: none;
    color: $color-primary;
    background-color: transparentize($color-special, $amount: 0.3);
    // opacity: 50%;
  }
  
  .btn-modal:hover {
    background-color: $color-special;
  }

  a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }



/* LAYOUT */
.modal-container {
  height: 95vh;
  overflow-y: scroll;
  max-width: 1300px;
  background-color: $color-tertiary;
  border-radius: 1rem;
  box-shadow: 0 5px 0 0  $color-medium-text;
}

.modal-card-header {
  background-color: $color-lighter-grey-background;
  width: 100%;
  height: 20vh;
  align-items: center;
  min-height: 200px;
}

.card-header {
  padding: 0;
}
.input-modal {
  border-radius: 0.5rem;
  border: solid 0.1rem $color-lighter-grey-background;
  padding: 1vh 1.5vw 1vh 1.5vw;
  color: $color-dark-text;
  font-size: larger;
}

.input-save {
  background-color: $color-special;
  border-radius: 0.5rem;
  border: solid 0.1rem $color-dark-text;
  padding: 1vh 1.5vw 1vh 1.5vw;
  color: $color-dark-text;
  font-size: larger;
}

.input-save:hover {
  background-color: transparentize($color: $color-special, $amount: 0.5);
}

.contact-save {
  background-color: $color-special;
  border-radius: 0.5rem;
  border: solid 0.1rem $color-dark-text;
  padding: 0.5vh 1vw 0.5vh 1vw;
  color: $color-dark-text;
  font-size: normal;
}

.contact-save:hover {
  background-color: transparentize($color: $color-special, $amount: 0.5);
}



.input-modal:hover {
  box-shadow:0 0 1px 2px  $color-special;
}

.textarea-modal:hover {
  box-shadow:0 0 1px 2px  $color-special;
}

.label-modal {
  line-height: 2;
  margin: 0 0;
  padding: 0.5vw 0.5vw 0 0.5vw ;
  font-size: larger;
  color: $color-secondary;
  font-size: 14px;
  font-weight: 200;
  text-transform: uppercase;
  align-content: flex-start;
}

.contact-img {
  display: inline-block;
  image-rendering: auto;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  object-fit: cover;
  background-size: 100% 100%;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #CECBC9;
  border-color:#ffffff;
  border-width:0.2rem;
}

@media(min-width: $breakpoint-phone) {
  .input-modal {
    min-width: 80vw;
    border-radius: 0.5rem;
    border: solid 0.1rem $color-dark-text;
    padding: 1vh 1.5vw 1vh 1.5vw;
    color: $color-dark-text;
    font-size: larger;
  }

  .textarea-modal {
    width: 70vw;
    border-radius: 0.5rem;
    border: solid 0.1rem $color-dark-text;
    padding: 1vh 1.5vw 1vh 1.5vw;
    color: $color-dark-text;
    font-size: larger;
    // margin: 0.5rem 0 0.5rem 0;
    
  }

}

@media (min-width: $breakpoint-tablet) {
  .input-modal {
    min-width: 40vw;
    border-radius: 0.5rem;
    border: solid 0.1rem $color-dark-text;
    padding: 1vh 1.5vw 1vh 1.5vw;
    color: $color-dark-text;
    font-size: larger;
  }

  .textarea-modal {
    width: 50vw;
    border-radius: 0.5rem;
    border: solid 0.1rem $color-dark-text;
    padding: 1vh 1.5vw 1vh 1.5vw;
    color: $color-dark-text;
    font-size: larger;
    // margin: 0.5rem 0 0.5rem 0;
    
  }

}

@media (min-width: $breakpoint-desktop) {
  .input-modal {
    min-width: fit-content;
    border-radius: 0.5rem;
    border: solid 0.1rem $color-dark-text;
    padding: 1vh 1.5vw 1vh 1.5vw;
    color: $color-dark-text;
    font-size: larger;
  }

  .textarea-modal {
    width: 550px;
    border-radius: 0.5rem;
    border: solid 0.1rem $color-dark-text;
    padding: 1vh 1.5vw 1vh 1.5vw;
    color: $color-dark-text;
    font-size: larger;
    // margin: 0.5rem 0 0.5rem 0;
    
  }

}
