$breakpoint-phone: 360px;
$breakpoint-tablet: 768px; 
$breakpoint-desktop: 1024px;

/* make the customizations */
/*$theme-colors: (
    "info": rgb(112, 73, 66),
    "danger": rgb(209, 98, 7),
    "primary":#032261,
    "dark": rgb(63, 63, 63),
    "light": #e8e9eb
);*/

$color-primary : #F6F6F6;
$color-secondary : #303551;
$color-special : #F2A90B;
$color-tertiary : #FFFFFF;
$color-lighter-grey-background: #C4C4C4;
$color-light-text: #000000;
$color-medium-text: #8C8C8C;
$color-dark-text: #000000;
$color-action: #EAEEF3;
$color-action-hover: #90b8f8;
$color-success:#158302;
$color-danger:#9e1e07;
$color-rewind: #FF6A00;
$color-green: #2baf1a;
/*
$color-primary : #fffaef;
$color-secondary : #2d465e;
$color-special : #FF6A00;
$color-tertiary : #FFFFFF;
$color-lighter-grey-background: #80A3C4;
$color-light-text: #000000;
$color-medium-text: #343434;
$color-dark-text: #000000;
$color-action: #80A3C4;
$color-action-hover: #343434;
$color-success:#2d465e;
$color-danger:#FF6A00;
$color-rewind: #FF6A00;
$color-green: #2baf1a;*/

.app-container {
  margin-bottom: 8em;
  position: relative;
  min-height: 100vh;
}

.content {
  min-height: 100vh;
}

html {
  height: 100%;
  position: relative;
}

body {
  height: 100%;
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  background: $color-primary;
  color: $color-light-text;
}

.text-medium {
  color: $color-medium-text;
  background-color: transparent;
  font-family: 'Roboto', sans-serif;
}

.text-custom {
  color: $color-secondary;
  background-color: transparent;
  border-radius: 35px;
  font-family: 'Roboto', sans-serif;
}

.text-custom:hover {
  color: $color-secondary;
  background-color: transparent;
  font-family: 'Roboto', sans-serif;
  border-radius: 35px;
}

.btn-select{
  background-color: $color-secondary;
  font-family: 'Roboto', sans-serif;
  color: $color-primary;
}
.btn-select:visited {
  background-color: $color-danger;
  font-family: 'Roboto', sans-serif;
  color: $color-primary;
}
.btn-select:focus {
  background-color: $color-success;
  color: $color-primary;
}
.btn-select:hover {
  background-color: $color-action;
  color: $color-primary;
}
.btn-select:active {
  background-color: $color-success;
  color: $color-primary;
}

.btn-custom {
  background-color: $color-lighter-grey-background;
  font-family: 'Roboto', sans-serif;
  color: $color-primary;
}

.btn-custom:hover {
  background-color: $color-secondary;
  font-family: 'Roboto', sans-serif;
  color: $color-primary;
}

.badge-custom {
  background-color: $color-special;
  font-family: 'Roboto', sans-serif;
  color: $color-primary;
}

.badge-custom:hover {
  background-color: $color-tertiary;
  font-family: 'Roboto', sans-serif;
  color: $color-secondary;
}

.btn-customxxl {
  padding: 1rem 1.5rem;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
}

.btn-circle.btn-xl {
  width: 40px;
  height: 40px;
  padding: 5px 5px;
  border-radius: 35px;
  font-size: 20px;
  line-height: 1;
  margin-bottom: auto;
  font-family: 'Roboto', sans-serif;
}

.btn-circle {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  font-size: 20px;
  line-height: 1;
  font-family: 'Roboto', sans-serif;
}
// Button Tutorial
.btn-tutorial {
  top: 2rem;
  right: 2rem;
  min-width: 120px;
  padding: 12px 10px;
  border-radius: 0.2rem;
  border: none;
  color: $color-primary;
  background-color: $color-lighter-grey-background;
  // opacity: 50%;
}

.btn-tutorial:hover {
  background-color: $color-secondary;
}

.floating-right {
  display: flex;
  flex-wrap: wrap;
  float: right;
}

.list-group-item-custom {
  color: $color-secondary;
  background-color: $color-tertiary;
  border-radius: 35px;
}

.list-group-item-custom:hover {
  color: $color-secondary;
  background-color: $color-tertiary;
  border-radius: 35px;
  box-shadow: 1px 1px 2px 2px $color-secondary;
}

.navbar {
  color: $color-tertiary;
  background: $color-secondary;
  font-family: 'Roboto', sans-serif;
  position: sticky;
}

.navbar-brand {
  display: flex;
  align-items: center;
}
.navbar-brand>img {
  padding: 7px 14px;
}

.navbar .tooltiptext1 {
  visibility: hidden;
  width: 120px;
  background-color:$color-special;
  color: $color-tertiary;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 50%;//was 100%
  left: 50%;
  margin-left: -60px;
}

.navbar:hover .tooltiptext1 {
  visibility: visible;
}

.homepage {
  height: 100vh;
  width: 100%;
  display: flex;
  background-size: cover;
  background-position: center;
  justify-content: center;
}

.errorMsg {
  color: $color-danger;
  background: $color-primary;
  padding: 0.5rem;
  margin-top: 0.5rem;
}

.youtube-video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow:hidden;
}

.youtube-video-container iframe, .youtube-video-container embed, .youtube-video-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.recording-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-container {
  overflow: hidden;
  position: relative;
  width:100%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  padding-bottom: 1vh;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
////// Pagination related. Modifying React Bootstrap pagination classes
// pagination mt-2
//pagination-parent mt-4
//pagination
//<Pagination.Item active={section.sectionNumber == sectionNum} key={section._id}>
//<Link className="pagination-block" 
.pagination {
  width: auto;
  //width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0.5rem;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem;
}
//style for list item active .page-item: active
.page-link {
  z-index: 0;
  font-size: 0.5rem;
  color: $color-secondary;
  background-color: $color-lighter-grey-background;
  border-color: $color-lighter-grey-background;
  margin-left: 0;
  border-radius: 0%;
  border: 0px;
  position: relative;
  display: block;
  padding: 0.2rem 0.2rem;
  //margin: 10px;
  line-height: 1.25;
}
.page-item {
  background: $color-primary;
  box-sizing: border-box;
  display: list-item;
}
.page-item:active {
  font-weight: bold;
  background: $color-lighter-grey-background;
}
.page-item:last-child .page-link {
  border-top-right-radius: 50%; 
  border-bottom-right-radius: 50%; 
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 50%; 
  border-bottom-left-radius: 50%;
}

.page-item.active .page-link {
  z-index: 0;
  color: $color-secondary;
  background-color :$color-special;
  border-color: $color-special;
}

//progress separator item
.pageline-item {
  z-index: 0;
  color: $color-secondary;
  background-color: $color-primary;
  border-color: $color-lighter-grey-background;
  width: 40px;
  height: 0;
  margin-left: 0;
  border-radius: 0%;
  padding-top: 10px;
  box-sizing: border-box;
  line-height: .5;
  display: block;
  position: relative;
}
.pageline-link {
  z-index: 0;
  color: $color-secondary;
  background-color: $color-primary;
  border-color: $color-lighter-grey-background;
  margin-left: 0;
  border-radius: 0%;
  position: relative;
  display: block;
  line-height: 0.5;
}
.pageline-item:active {
  font-weight: bold;
  background: $color-lighter-grey-background;
}
.pageline-item:last-child .page-link {
  border-top-right-radius: 0; 
  border-bottom-right-radius: 0; 
}

.pageline-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0; 
  border-bottom-left-radius: 0;
}

.pagination-block {
  color: $color-secondary;
  text-decoration: none;
  background-color: transparent;
}
//**********

// Terms CSS
#terms {
  font-family: 'Roboto', sans-serif;
  font-size: 0.5rem;
  font-weight: 100;
  line-height: 1.5;
  color: var(--dark-gray);
}

// BootCamp CSS
#bootcamp {
  // padding: 20px 30px 75px 30px;
  //font-family: var(--standard-font-family);
  font-family: 'Roboto', sans-serif;
  // font-size: 20px;
  line-height: 1.5;
  color: var(--dark-gray);
}

blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}


#bootcamp pre {
  // width: 660px;
  margin: 50px auto;
  overflow-x: auto;
  word-break: normal;
}

#bootcamp blockquote {
  box-sizing: border-box;
  border-left: 5px solid var(--light-gray);
  color: var(--med-gray);
  // width: 660px;
  margin: 50px auto;
  padding-left: 20px;
}

#bootcamp blockquote.collapse.pro-tip, #bootcamp blockquote.collapse.shortcut {
  border-radius: 46px 0px 0px 44px;
  box-shadow: none;
  // width: 745px;
  height: 90px;
  display: flex;
  left: 0px;
}
#bootcamp blockquote.collapse.pro-tip>strong.blockquote-title::before, #bootcamp blockquote.collapse.shortcut>strong.blockquote-title::before {
  content: "show ";
}

#bootcamp blockquote.collapse.pro-tip>strong.blockquote-title, #bootcamp blockquote.collapse.shortcut>strong.blockquote-title {
  border: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-left: 40px;
  align-self: center;
}

#bootcamp blockquote.pro-tip>div>p:first-child, #bootcamp blockquote.shortcut>div>p:first-child {
  margin-top: 0px;
}

#bootcamp blockquote.pause {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 15px;;
  border: solid 0.3rem $color-secondary;
  border-radius: 1rem;
  position: relative;
}

#bootcamp blockquote.hint {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 15px;;
  border: solid 0.3rem $color-success;
  border-radius: 1rem;
  position: relative;
}

#bootcamp blockquote.rewind{
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 15px;;
  border: solid 0.3rem $color-rewind;
  border-radius: 1rem;
  position: relative;
}

#bootcamp blockquote.pro-tip {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 15px;;
  border: solid 0.3rem $color-action;
  border-radius: 1rem;
  position: relative;
}

#bootcamp blockquote.deep-dive {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 15px;;
  border: solid 0.3rem $color-action-hover;
  border-radius: 1rem;
  position: relative;
}

#bootcamp blockquote.important {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 15px;;
  border: solid 0.3rem $color-danger;
  border-radius: 1rem;
  position: relative;
}

#bootcamp blockquote.on-the-job {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 15px;;
  border: solid 0.3rem $color-secondary;
  border-radius: 1rem;
  position: relative;
}

#bootcamp blockquote.connect-the-dots {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 15px;;
  border: solid 0.3rem $color-success;
  border-radius: 1rem;
  position: relative;
}

#bootcamp blockquote.nerd-note {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 15px;;
  border: solid 0.3rem $color-secondary;
  border-radius: 1rem;
  position: relative;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

#bootcamp img:not(.equation_image):not(.homepage_image) {
  border: 2px solid var(--light-gray);
  padding: 15px;
  border-radius: 5px;
  max-width: 90% !important;
  margin: 50px auto;
  display: block;
}


#bootcamp blockquote.title-above.important strong.blockquote-title {
  color: var(--primary);
  border: 2px solid var(--dark-blue);
  border-radius: 1rem;
  background-color: var(--white);
}

#bootcamp blockquote.title-above strong.blockquote-title {
  line-height: 30px;
  padding: 5px 10px;
  width: fit-content;
  width: -moz-max-content;
  color: var(--white);
  margin-bottom: 10px;
  display: block;
  font-size: 19px;
  background-color: var(--med-gray);
  text-transform: uppercase;
  border: none;
}

code {
  padding: 0.125rem 0.25rem;
  color: $color-danger;
}

code, pre {
  font-family: Monaco,Menlo,Consolas,"Courier New",monospace;
    font-size: 0.75rem;
    border-radius: 6px;
    background-color: $color-primary;
    width:auto;

}

#bootcamp code, #bootcamp pre {
  font-size: 1rem;
}
/*  END OF BOOTCAMP CSS*/


.active {
  font-weight: bold;
  background: $color-primary;
}

.home-text {
  text-align: center;
}

.card {
  border: none;
}

.dynamic-content-div img {
  /* Set width of image to maximum width of Card, height will follow aspect ratio */
  width: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}

.searchcard:hover {
  box-shadow: 1px 1px 2px 2px $color-secondary;
}
/* Login Page*/
.logincard { 
  margin-top: 4vh;
  border: solid  0.5rem $color-tertiary;
  background-color: $color-tertiary;
  border-radius: 15px;
}

.donatecard {
  border: none;
  width: 18rem;
  height: 20rem;
  border-radius: 1rem;
  margin: auto;
}

.modulecard {
  border: none;
  width: 18rem;
  height: 20rem;
  border-radius: 1rem;
  box-shadow: 1px 1px 2px 2px $color-lighter-grey-background
}

.modulecard:hover {
  box-shadow: 1px 1px 2px 2px $color-secondary;
}

.modulecard-img {
  // width: auto;
  // height: auto;
  aspect-ratio: attr(width) / attr(height);
}

.lessoncard {
  border: none;
  width: 30rem;
  height: 20rem;
  border-radius: 1rem;
}
.lessoncard:hover {
  box-shadow: 1px 1px 2px 2px $color-secondary;
}

.sectioncard {
  border: none;
  width: 30rem;
  height: 20rem;
  border-radius: 1rem;
}

.sectioncard:hover {
  box-shadow: 1px 1px 2px 2px $color-secondary;
}

.card-deck{
  justify-content: center;
}

.card-body {
  color: $color-dark-text;
}

.card-columns {
  flex-direction: row;
}

.card-img-top {
  max-height: 75vh;
}


/*******/////////
.card-sidebarmodule {
  margin: auto;
  border: solid  0.5rem $color-lighter-grey-background;
  background-color: $color-lighter-grey-background;
  color: $color-tertiary;
  font-size: 0.5rem;
  font-weight: 100;
  font-style: normal;
  text-align: left;
  width: 120px;
  border-radius: 0%;
}

.card-sidebarmodule:hover {
  color: $color-secondary
}

.card-sidebarmodule:active {
  color: $color-secondary
}

.cardsidebarmodule-link {
  color: $color-tertiary;
  font-size: 0.5rem;
  text-align: left;
}
.cardsidebarmodule-link:hover {
  color: $color-secondary;
}
/*******/////////

.small {
  font-weight: 300;
  font-style: italic;
}

 .pill-center {
   width: max-content;
  }


.no-bullets {
  list-style: none;
}

.content {
  flex: 1 0 auto;
}

.spinner {
  position:fixed;
  top: 25%;
  left: 50%;
}

footer {
  flex-shrink: 0;
  padding: 30px;
  color: $color-tertiary;
  background-color: $color-secondary;
  border-top: 1px solid $color-primary;
  height: 80px;
  width: 100%;
  text-align: center;
  /*addition*/
  position: relative;
  display: inline-block;
}

.page-footer {
  background-color: $color-tertiary;
}

.footer-link {
  color: $color-special;
  font-size: 0.8rem;
}
.footer-link:hover {
  color: $color-lighter-grey-background;
}

// show / hide password
.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}

.i-pass {
  position: absolute;
  top: 20%;
  right: 5%;
}

.i-pass:hover {
  color: $color-special;
  cursor: pointer;
}

.i-pass-show {
  position: absolute;
  top: 20%;
  right: 5%;
  color: $color-special;
  cursor: pointer;
}

//new purely CSS Tooltip. Not used
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: $color-special;
  font-size: 0.5rem;
  color: $color-tertiary;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
}
//new //new purely CSS Tooltip. Not used
.tooltip:hover .tooltiptext {
  visibility: visible;
}
//end of tooltip class

.image-right {
  width: 50%;
  position: relative;
  justify-content: end;
}

//clear badge - is badge with navbar and footer background color, for icon tooltip
.badge-clear {
  background-color: Transparent;
  //$color-secondary;
  font-family: 'Roboto', sans-serif;
  color: $color-tertiary;
  border-radius: 50%;
}

//SidebarComplete badge - is badge to track modules in Sidebar
.badge-SidebarComplete {
  background-color: $color-green;
  //$color-secondary;
  font-family: 'Roboto', sans-serif;
  font-size: 0.7rem;
  color: $color-tertiary;
  border-radius: 20%;
}

//Sidebar badge - is badge to track modules in Sidebar
.badge-Sidebar {
  background-color: $color-primary;
  //$color-secondary;
  font-family: 'Roboto', sans-serif;
  font-size: 0.7rem;
  color: $color-secondary;
  border-radius: 20%;
}
//first working Sidebar related for sidebar component 
#wrapper {
	overflow-x: hidden;
	&.toggled {
		#sidebar-wrapper {
			margin-left: 0;
			margin-left: -15rem;
		}
	}
}
#sidebar-wrapper {
	min-height: 100vh;
	margin-left: -15rem;
	-webkit-transition: margin .25s ease-out;
	-moz-transition: margin .25s ease-out;
	-o-transition: margin .25s ease-out;
	transition: margin .25s ease-out;
	.sidebar-heading {
		padding: 0.875rem 1.25rem;
		font-size: 1.2rem;
	}
	.list-group {
		width: 15rem;
	}
}
///////
#page-content-wrapper {
	min-width: 100vw;
	min-width: 0;
	width: 100%;
}

//CSS clouds - alterative to spinner
.clouds{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: $color-secondary;
	color: $color-special;
}
.cloud{
	width: 22.5em;
	height: 7.5em;
	background: $color-primary;
	-moz-border-radius: 6.25em;
	-webkit-border-radius: 6.25em;
	border-radius: 6.25em;
	position: relative;
	margin: 7.5em auto 1.25em;
}
.cloud:after, .cloud:before{
	content: "";
	position: absolute;
	background: $color-primary;
	z-index: -1;
	border-radius: 50%;
}
.cloud:after{
	width: 7.5em;
	height: 7.5em;
	top: -3.125em;
	left: 3.125em;
}
.cloud:before{
	width: 11.25em;
	height: 11.25em;
	top: -5.625em;
	right: 3.125em;
}
.cloud.s1{
	-moz-transform: scale(0.9, 0.9);
	-ms-transform: scale(0.9, 0.9);
	-webkit-transform: scale(0.9, 0.9);
	transform: scale(0.9, 0.9);
	-webkit-animation: moveclouds 30s linear infinite;
	-moz-animation: moveclouds 30s linear infinite;
	-o-animation: moveclouds 30s linear infinite;
}
.cloud.s2{
	left: 12.5em;
	-moz-transform: scale(0.7, 0.7);
	-ms-transform: scale(0.7, 0.7);
	-webkit-transform: scale(0.7, 0.7);
	transform: scale(0.7, 0.7);
	-webkit-animation: moveclouds 50s linear infinite;
	-moz-animation: moveclouds 50s linear infinite;
	-o-animation: moveclouds 50s linear infinte;
}
.cloud.s3{
	left: -15.625em;
	top: -12.5em;
	-moz-transform: scale(0.4, 0.4);
	-ms-transform: scale(0.4, 0.4);
	-webkit-transform: scale(0.4, 0.4);
	transform: scale(0.4, 0.4);
	-webkit-animation: moveclouds 40s linear infinite;
	-moz-animation: moveclouds 40s linear infinite;
	-o-animation: moveclouds 40s linear infinite;
}
.cloud.s4{
	left: 29.375em;
	top: -35.65em;
	-moz-transform: scale(0.5, 0.5);
	-ms-transform: scale(0.5, 0.5);
	-webkit-transform: scale(0.5, 0.5);
	transform: scale(0.5, 0.5);
	-webkit-animation: moveclouds 36s linear infinite;
	-moz-animation: moveclouds 36s linear infinite;
	-o-animation: moveclouds 36s linear infinite;
}
.cloud.s5{
	left: -38em;
	top: -38em;
	-moz-transform: scale(0.3, 0.3);
	-ms-transform: scale(0.3, 0.3);
	-webkit-transform: scale(0.3, 0.3);
	transform: scale(0.3, 0.3);
	-webkit-animation: moveclouds 44s linear infinite;
	-moz-animation: moveclouds 44s linear infinite;
	-o-animation: moveclouds 44s linear infinte;
}

@-webkit-keyframes moveclouds{
	0%{
		margin-left: 125em;
	}
	100%{
		margin-left: -125em;
	}
}
@-moz-keyframes moveclouds{
	0%{
		margin-left: 125em;
	}
	100%{
		margin-left: -125em;
	}
}
@-o-keyframes moveclouds{
	0%{
		margin-left: 125em;
	}
	100%{
		margin-left: -125em;
	}
}

////CSS clouds

@media(min-width: $breakpoint-phone) {

  #display-hide {
    display: none;
  }

  .card-columns {
    column-count: 1;
  }

  .card-deck{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  hr {
    margin-top: 1vh;
    margin-bottom: 1vh;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    width: 50%;
  }

  .lessoncard {
    border: none;
    width: 18rem;
    height: 20rem;
    border-radius: 1rem;
  }
  
  .sectioncard {
    border: none;
    width: 18rem;
    height: 20rem;
    border-radius: 1rem;
  }

  .pill-center {
    width: max-content;
  }


/* //W3 sidebar related work in progress - Sidebar On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) 
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
  */
} 


@media (min-width: $breakpoint-tablet) {
  .card-columns {
    column-count: 2;
  }

  .card-deck{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    width: 75%;
  }

  .lessoncard {
    border: none;
    width: 28rem;
    height: 20rem;
    border-radius: 1rem;
  }
  
  .sectioncard {
    border: none;
    width: 28rem;
    height: 20rem;
    border-radius: 1rem;
  }

}

@media (min-width: $breakpoint-desktop) {
  .card-columns {
    column-count: 3;
  }

  .card-deck{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    width: 80%;
  }

  .lessoncard {
    border: none;
    width: 35rem;
    height: 20rem;
    border-radius: 1rem;
  }
  
  .sectioncard {
    border: none;
    width: 35 rem;
    height: 20rem;
    border-radius: 1rem;
  }

  .navbar {
    max-height: 75px;
    padding: 2vh 1.5vw;
  }
}